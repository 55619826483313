export default [
  {
    title: 'My video is hosted on YouTube?',
    content: 'No, it\'s not. We use only the opening video as background to the text you created from YouTube.',
  },
  {
    title: 'Can I use Seasons 2-8 openings instead of the first one?',
    content: `In the future, we want to give the option to use Seasons 2-8 openings too, but by now is only possible to use the Season 1.
              Get in contact with us, if we have more people interested we can prioritize this.`,
  },
  {
    title: 'My video will have the logo watermark? It\'s possible to remove it?',
    content: 'Yes, it will have the watermark as seen in preview. But we have the option to pay and receive your video earlier without the watermark. See more information on the download page. ',
  },
];
