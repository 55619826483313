import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`7. Game of Thrones Intro Creator`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.1.`}</MDXTag>{` This website is not related or to HBO. The music, the Game of Thrones logo and images used in the background video are copyrights of HBO.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`7.2.`}</MDXTag>{` The video has watermark due to our work on removing the text to allow customization.
Free videos and requests with the minimum payment amount will have the watermark.
There's an option in a greater amount to request the video with the watermark removed.
Check more information on the download page.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      