import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/game-of-thrones';
import Terms from '../termsOfService/game-of-thrones.mdx';

const SecondPage = () => (
  <ProductPageLayout
    title="Game of Thrones Intro Creator"
    emailAlias="gameofthrones"
    website="https://gameofthronesintrocreator.kassellabs.io"
    faq={faq}
    terms={<Terms />}
  />
);

export default SecondPage;
